import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import "./App.css";
import AppRoutes from "./Routes/Routes";

const App = () => {

  useEffect(() => {
    ReactPixel.init("2019085451921560"); // Replace with your Facebook Pixel ID
    ReactPixel.pageView(); // Track page view
  }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
};

export default App;
