import React, { useState } from 'react'
import ParticlesBg from "particles-bg";
import { Fade } from "react-awesome-reveal";
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ShareIcon from '@mui/icons-material/Share';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import white_logo from "../assets/logo_white.png"
import icon from "../assets/ic_door.png"
import iconK from "../assets/ic_kitchen.png"
import iconC from "../assets/ic_carpentry.png"
import { Button, Grid, IconButton, Snackbar, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import TikTokIcon from '../assets/ic_tiktok.png'
import Background from '../assets/background_video.mp4'

const UserCard = (props) => {

    let { userId } = useParams();

    const data = {
        name: "Al-Qattan Home",
        description: "Elevate Your Space! Kitchen Marvels, Cabinets, and Doors Tailored for You!",
        instagram: "https://www.instagram.com/alqattan.home?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
        location: "https://maps.app.goo.gl/hsRErgFHwyZwNx1h7",
        tiktok: "https://vm.tiktok.com/ZSFycpbqx/",
        phoneNumber: "+96599402876"
    }
    const persons = [
        // {
        //     id: 1,
        //     name: "AR. AHMAD ABOUSEIDOU",
        //     number: "+965 99402876",
        //     wapNumber: "+96599402876",
        // },
        {
            id: 2,
            name: "AR. HASAN ISHAQ",
            number: "+965 9402 5136",
            wapNumber: "+96594025136",
        },
        {
            id: 3,
            name: "ENG. FERAS ZAKARIA LIBDEH",
            number: "+965 9889 4632",
            wapNumber: "+96598894632",
        },
        {
            id: 4,
            name: "ENG. ABU FERAS",
            number: "+965 9721 7833",
            wapNumber: "+96597217833",
        },
        {
            id: 5,
            name: "MR. MOHAMMED AL ARABI",
            number: "+965 9721 9046",
            wapNumber: "+96597219046",
        }
    ]

    let currentUser = persons.find(per => per.id == userId)

    if (currentUser == undefined) {
        currentUser = persons[0]
    }

    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = async () => {
        // Create a temporary textarea element to copy the text
        const shareData = {
            url: window.location.href,
        }
        if (navigator.share && navigator.canShare(shareData)) {
            navigator.share(shareData)
        } else {

            const textarea = document.createElement('textarea');
            textarea.value = window.location.href;

            // Append the textarea to the document
            document.body.appendChild(textarea);

            // Select the text inside the textarea
            textarea.select();

            // Execute the copy command
            document.execCommand('copy');

            // Remove the temporary textarea
            document.body.removeChild(textarea);

            // Set the state to indicate that the URL has been copied
            setIsCopied(true);

            // Reset the copied state after a short delay
            setTimeout(() => setIsCopied(false), 2000);
        }
    };

    return (
        <div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', width: '100%', position: 'absolute'
            }}>

                <Fade duration={600} direction='up'>
                    <img style={{ height: 200, width: 200 }} src={white_logo} alt='Logo' />
                </Fade>
                <Fade duration={900} direction='up'>
                    <h2 style={{ fontSize: 35, color: 'white', font: " 'opensans-bold', sans-serif", marginBottom: 30 }}>{data.name}</h2>
                </Fade>
                <Fade duration={1000} direction='up' >
                    <p style={{
                        marginBottom: 25, color: 'white', maxWidth: 550,
                        textAlign: 'center',
                        marginLeft: 30,
                        marginRight: 30,
                        fontSize: 15,
                        font: "18px/1.9em 'librebaskerville-regular', serif"
                    }}>{data.description}</p>
                </Fade>

                <Fade duration={1200} direction='up'>
                    <Grid container style={{ marginTop: 20, marginBottom: 90 }} spacing={2}>
                        <Grid item xs={1} md={3} />
                        <Grid item xs={10} md={6}>
                            <Button variant="outlined"
                                disabled
                                fullWidth
                                style={{ color: 'white', borderColor: 'white', fontSize: 12 }}
                                startIcon={<PersonOutlineOutlinedIcon />}>
                                {currentUser.name}
                            </Button>
                        </Grid>
                        <Grid item xs={1} md={3} />
                        <Grid item xs={1} md={3} />
                        <Grid item xs={10} md={6}>
                            <Button href={`https://wa.me/${currentUser.wapNumber}`} variant="outlined"
                                fullWidth
                                style={{ color: 'white', borderColor: 'white', fontSize: 12 }}
                                startIcon={<WhatsAppIcon />}>
                                {currentUser.number}
                            </Button>
                        </Grid>
                        <Grid item xs={1} md={3} />
                        <Grid item xs={1} md={3} />
                        <Grid item xs={10} md={6}>
                            <Button variant="outlined"
                                fullWidth
                                href='/catalogue'
                                style={{ color: 'white', borderColor: 'white', fontSize: 12 }}
                                startIcon={<MenuBookOutlinedIcon />}>
                                View Catalogue
                            </Button>
                        </Grid>
                        <Grid item xs={1} md={3} />
                    </Grid>
                </Fade>
                <Fade duration={1400} direction='up'>
                    <Stack direction={'row'} alignSelf={'center'} style={{ justifyContent: 'center' }}>
                        <IconButton
                            href={data.instagram}
                            aria-label="instagram" style={{ color: 'rgba(255,255,255,0.45)' }}>
                            <InstagramIcon sx={{ height: 35, width: 35 }} />
                        </IconButton>
                        <IconButton
                            href={data.location}
                            aria-label="Location" style={{ color: 'rgba(255,255,255,0.45)' }}>
                            <FmdGoodOutlinedIcon sx={{ height: 35, width: 35 }} />
                        </IconButton>
                        <IconButton
                            href={data.tiktok}
                            aria-label="TikTok" style={{ color: 'rgba(255,255,255,0.45)' }}>
                            <img src={TikTokIcon} style={{ height: 30, width: 30, opacity: 0.45 }} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                window.open(`tel:${currentUser.wapNumber}`);
                            }}
                            aria-label="Phone" style={{ color: 'rgba(255,255,255,0.45)' }}>
                            <LocalPhoneOutlinedIcon sx={{ height: 35, width: 35 }} />
                        </IconButton>
                    </Stack>
                </Fade>

                <Snackbar
                    open={isCopied}
                    autoHideDuration={1800}
                    message="Link copied successfully!"
                />
            </div>

            <IconButton
                onClick={copyToClipboard}
                aria-label="share" style={{ color: 'white', position: 'absolute', left: 10, top: 10 }}>
                <Fade duration={1400} >
                    <ShareIcon sx={{ height: 35, width: 35 }} />
                </Fade>
            </IconButton>

        </div >
    )
}

export default UserCard