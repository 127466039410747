import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import UserCard from "../Page/UserCard";
import Catalogue from "../Page/Catalogue";
import ComingSoon from "../Page/ComingSoon";
import HomePage from "../Page/HomePage";
import React from "react";
import ChatWithUs from "../Page/ChatWithUs";
import PDFUpload from "../Page/PDFUpload";

export default function AppRoutes() {

    const router = createBrowserRouter([
        {
            path: "/:userId",
            element: <UserCard />,
        },
        {
            path: "/",
            element: <Navigate to="/home" />
        },
        {
            path: "/catalogue",
            element: <Catalogue />,
            children: [{
                path: ":showCatalogue",
                element: <Catalogue />,
            }],
        },
        {
            path: "/upload",
            element: <PDFUpload />,
        },
        {
            path: "/contactus",
            element: <ChatWithUs contactUsType={'doors'} />,
        },
        {
            path: "/contactuskitchen",
            element: <ChatWithUs contactUsType={'kitchen'} />,
        },
        {
            path: "/contactuscarpentry",
            element: <ChatWithUs contactUsType={'carpentry'} />,
        },
        {
            path: "/comingsoon",
            element: <ComingSoon />,
            children: [{
                path: ":showCatalogue",
                element: <ComingSoon />,
            }],
        },
        {
            path: "/home",
            element: <HomePage />,
            children: [{
                path: ":showCatalogue",
                element: <HomePage />,
            }],
        },

    ]);

    return (
        <div>
            <React.StrictMode>
                <RouterProvider router={router} />
            </React.StrictMode>
        </div>
    );
}
